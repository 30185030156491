<template>
  <div class="event-modal-box" :class="{ [type]: true }">
    <div class="box-title-wrapper">
      <component :is="icon" v-if="icon" />
      <slot name="title">
        <div class="box-title">
          {{ title }}
        </div>
      </slot>
    </div>
    <slot />
  </div>
</template>

<script>
import { computed } from 'vue';

import { NoticeIcon, ApprovedIcon } from '@/assets/icons';

export const BOX_TYPE = {
  NEUTRAL: 'neutral',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};
export default {
  name: 'Box',
  components: { NoticeIcon, ApprovedIcon },
  props: {
    title: { type: String, default: '' },
    type: { type: String, default: 'neutral', validator: (value) => Object.values(BOX_TYPE).indexOf(value) !== -1 },
  },
  setup(props) {
    const icon = computed(() => {
      switch (props.type) {
        case BOX_TYPE.ERROR:
        case BOX_TYPE.WARNING:
          return NoticeIcon;

        case BOX_TYPE.SUCCESS:
          return ApprovedIcon;

        default:
          return '';
      }
    });

    return {
      icon,
      BOX_TYPE,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
@import '../style.scss';
.event-modal-box {
  &.success {
    > div > svg {
      color: $success;
    }
    border-color: $success !important;
    background: change-color($color: $success, $lightness: 98%) !important;
  }
  &.warning {
    > div > svg {
      color: $warning;
    }
    border-color: $warning !important;
    background: change-color($color: $warning, $lightness: 98%) !important;
  }
  &.error {
    > div > svg {
      color: $error;
    }
    border-color: $error !important;
    background: change-color($color: $error, $lightness: 98%) !important;
  }
}
</style>
