<template>
  <el-dialog
    visible
    :title="$t(`eventMapModal.updateProductModule.${updateType}`)"
    append-to-body
    custom-class="change-modal"
    @close="$emit('close')"
  >
    <div class="card">
      <div class="card-body change-modal__info">
        <div class="change-modal__info__icon">
          <InformationIcon width="16px" height="16px" />
        </div>
        <div class="change-modal__info__text">
          <div class="change-modal__info__text__title fw-bold">
            {{ product.name || $t('commons.generalProduct') }}
          </div>
          <div v-if="product.sku">{{ $t('commons.sku') }}: {{ product.sku }}</div>
        </div>
      </div>
    </div>
    <div class="card mt-4">
      <div class="card-body change-modal__form">
        <div>
          <div class="change-modal__form__current-value-title">
            {{ $t(`eventMapModal.updateProductModule.inputs.currentValue.${updateType}`) }}
          </div>
          <div class="change-modal__form__current-value-text fw-bold">
            {{ value }}
          </div>
        </div>
        <div>
          <div>
            {{ $t(`eventMapModal.updateProductModule.inputs.newValue.${updateType}`) }}
          </div>
          <el-form ref="form" :model="inputForm">
            <el-form-item prop="newValue" :rules="inputRule">
              <el-input v-model.number="inputForm.newValue" class="change-modal__input" type="number" />
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <template #footer>
      <el-button @click="$emit('close')">{{ $t('commons.cancel') }}</el-button>
      <el-button type="primary" :loading="false" @click="handleConfirm"> {{ $t('commons.apply') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';

import { InformationIcon } from '@/assets/icons';

export const UPDATE_TYPE = {
  QUANTITY: 'quantity',
  DISCOUNT: 'discount',
  PRICE: 'price',
};

export default {
  name: 'UpdateProductModal',
  components: { InformationIcon },
  props: {
    product: { type: Object, required: true },
    value: { type: String, required: true },
    updateType: {
      type: String,
      required: true,
      validator: (value) => Object.values(UPDATE_TYPE).indexOf(value) !== -1,
    },
    activeTab: { type: Number, required: true },
  },

  setup() {
    const root = getCurrentInstance().proxy;
    const inputRule = computed(() => [
      {
        required: true,
        type: 'number',
        message: root.$i18n.t('delivery.addDeliveryItemModal.inputs.rules.quantity.empty'),
      },
      {
        validator: (__, value, callback) => {
          if (value < 0)
            return callback(
              new Error(root.$i18n.t('delivery.addDeliveryItemModal.inputs.rules.quantity.negative')),
              {}
            );
          callback();
        },
      },
    ]);
    const inputForm = ref({
      newValue: null,
    });
    return {
      inputRule,
      inputForm,
    };
  },
  methods: {
    handleConfirm() {
      this.$refs.form.validate(async (isValid) => {
        if (isValid) {
          const data = {};
          switch (this.updateType) {
            case UPDATE_TYPE.DISCOUNT:
            case UPDATE_TYPE.PRICE:
              data[this.updateType] = this.inputForm.newValue;
              break;
            default:
              data[this.updateType] = this.activeTab === 1 ? this.inputForm.newValue * -1 : this.inputForm.newValue;
              break;
          }
          this.$emit('update', data);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
@import '../../commons/style.scss';

:deep(.change-modal) {
  width: 30%;

  .el-dialog__title {
    font-size: 1.5em;
    font-weight: $font-weight-bold;
  }
  .el-dialog__header {
    &::after {
      border-bottom: 1px solid $outline;
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      transform: translate(141px, 34px);
    }
  }

  input {
    text-align: center;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  .change-modal__info {
    display: grid;
    grid-template-columns: 1fr 30fr;
    gap: 5px;

    .change-modal__info__icon {
      color: $svg-gray-color;
    }
  }
  .change-modal__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    text-align: center;

    .change-modal__form__current-value-title {
      margin-bottom: 7px;
    }
    .change-modal__form__current-value-text {
      font-size: 1.2em;
    }
  }
}
</style>
