<template>
  <el-dialog
    visible
    append-to-body
    top="3%"
    :title="$t(`eventMapModal.${eventType}Card.differenceModal.title`)"
    custom-class="difference-modal"
    @close="$emit('close')"
  >
    <DifferenceProduct
      :difference="difference"
      :customer-order-item="customerOrderItem"
      :diffs-to-show="diffsToShow"
      :document="document"
      :supplier="supplier"
      :business="business"
      :date="date"
      :supplier-order-item="supplierOrderItem"
    >
      <slot />
    </DifferenceProduct>
    <template #footer>
      <Button type="secondary" @click="$emit('close')">{{ $t('commons.cancel') }}</Button>
      <Button :disabled="!customerOrderItem.id" @click="$emit('apply')">{{ $t('commons.apply') }}</Button>
    </template>
  </el-dialog>
</template>

<script>
import { Button } from '@/modules/core/components';
import DifferenceProduct from './DifferenceProduct';

export const DIFFERENCE_PROPERTY = {
  QUANTITY: 'quantity',
  PRICE: 'price',
  DISCOUNT: 'discount',
  NET: 'net',
  TOTAL: 'total',
};

export default {
  components: { DifferenceProduct, Button },
  props: {
    eventType: { type: String, required: true },
    customerOrderItem: { type: Object, required: true },
    difference: { type: Object, required: true },
    diffsToShow: {
      type: Array,
      required: true,
      validator: (array) =>
        array.length <= Object.values(DIFFERENCE_PROPERTY).length &&
        array.every((property) => Object.values(DIFFERENCE_PROPERTY).indexOf(property) !== -1),
    },
    document: { type: Object, required: true },
    supplier: { type: Object, required: true },
    business: { type: Object, required: true },
    date: { type: Date, required: true },
    supplierOrderItem: { type: Object, default: null },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

::v-deep .difference-modal {
  border-radius: 6px;
  color: red;
  width: 38em;
  margin-bottom: 0;
  padding: 1em;

  .el-dialog__header {
    padding: 1.25em 0;
  }

  .el-dialog__title {
    font-weight: $font-weight-bold;
    font-size: 1.5em;
  }

  .el-dialog__body {
    padding: 0;
  }
}
</style>
