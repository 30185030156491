<template>
  <el-dialog
    visible
    :show-close="false"
    append-to-body
    custom-class="rounded mt-5 mb-0 event-modal-content"
    @close="$emit('close')"
  >
    <component :is="eventComponent" :event-type="activity.type" :event-id="activity.id" @close="$emit('close')" />
  </el-dialog>
</template>

<script>
import { Button } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';

import Order from './components/order';
import Delivery from './components/delivery';
import Billing from './components/billing';
import Payment from './components/payment';

const components = {
  order: Order,
  delivery: Delivery,
  billing: Billing,
  payment: Payment,
};

export default {
  components: { Button, CloseIcon },
  props: {
    activity: { type: Object, required: true },
  },
  data() {
    return {
      eventComponent: components[this.activity.type],
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep .event-modal-content {
  height: 95vh;
  width: 85vw;
  overflow: hidden;

  .el-dialog__header,
  .el-dialog__body {
    padding: 0;
    color: $typography-primary;
  }

  .el-dialog__body {
    height: 100%;
  }
}
</style>
//
