<template>
  <Box :title="$t('eventMapModal.paymentCard.alertBox.success.matchingTitle')" :type="type">
    <div class="summary-content">
      <slot />
    </div>
    <hr class="event-modal-card-boxes-hr--doted" />
    <div class="summary-footer">
      <div class="d-flex justify-content-between align-items-center fw-bold">
        <div>{{ $t('eventMapModal.commons.alertBox.recordOf', { name: customerName }) }}</div>
        <div class="confirmed-event">
          <ApproveIcon />
          {{ $t('eventMapModal.commons.alertBox.eventConfirmed') }}
        </div>
      </div>
      <div class="record-row fw-bold">
        <div class="business-text">{{ $t('eventMapModal.commons.alertBox.recordOf', { name: supplierName }) }}</div>
        <span v-if="documents[0].id">
          <template v-for="doc in documents">
            <div :key="doc.id" class="align-text-to-end">
              <Button type="link" class="p-0 m-0" @click.stop="handleOpenDocument(doc.id, documentsIds)">
                {{ $t(`document.exports.schema.type.shortName.${doc.type}`) }} {{ doc.documentNumber }}
              </Button>
            </div>
          </template>
        </span>
        <span v-else>-</span>
      </div>
    </div>
    <DocumentModal
      v-if="displayedDocument.documentId"
      visible
      :document-id="displayedDocument.documentId"
      @close="displayedDocument = {}"
    >
      <template v-if="displayedDocument.documentsIds && displayedDocument.documentsIds.length > 1" #header>
        <el-pagination
          layout="prev, pager, next"
          small
          background
          :page-size="1"
          :total="displayedDocument.documentsIds.length"
          :current-page="displayedDocument.index + 1"
          :pager-count="5"
          @update:currentPage="handleDocumentModalPagination"
        />
      </template>
    </DocumentModal>
  </Box>
</template>

<script>
import { ref } from 'vue';

import { ApproveIcon } from '@/assets/icons';
import { DocumentModal } from '@/modules/documentModal';
import { Button } from '@/modules/core';

import Box, { BOX_TYPE } from './Box';

export default {
  components: { Box, DocumentModal, Button, ApproveIcon },
  props: {
    type: { type: String, required: true },
    customerName: { type: String, required: true },
    supplierName: { type: String, required: true },
    documents: { type: Array, default: () => [] },
  },
  setup() {
    return {
      BOX_TYPE,
      displayedDocument: ref({}),
    };
  },
  computed: {
    documentsIds() {
      return this.documents.map(({ id }) => id);
    },
  },
  methods: {
    handleOpenDocument(documentId, documentsIds) {
      this.displayedDocument = {
        documentId,
        documentsIds,
        index: documentsIds.indexOf(documentId),
      };
    },
    handleDocumentModalPagination(page) {
      if (page - 1 === this.displayedDocument.index) return;
      const nextIndex = page - 1;
      this.displayedDocument = {
        ...this.displayedDocument,
        documentId: this.displayedDocument.documentsIds[nextIndex],
        index: nextIndex,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.summary-footer {
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .record-row {
    display: flex;
    justify-content: space-between;

    .business-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .confirmed-event {
    display: flex;
    align-items: center;
    gap: 5px;
    color: $success;
  }
  .document-link {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.align-text-to-end {
  text-align: end;
}
</style>
